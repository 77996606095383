// PasswordContext.js

import React, { createContext, useState } from "react"

export const PasswordContext = createContext()

export const PasswordProvider = ({ children }) => {
  const [password, setPassword] = useState("")
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false)

  const handlePasswordChange = e => {
    setPassword(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const correctPassword = "mec2023" // Replace with your actual password
    if (password === correctPassword) {
      setIsPasswordCorrect(true)
    } else {
      setPassword("")
    }
  }

  return (
    <PasswordContext.Provider
      value={{
        password,
        isPasswordCorrect,
        handlePasswordChange,
        handleSubmit,
      }}
    >
      {children}
    </PasswordContext.Provider>
  )
}
